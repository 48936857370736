.Testimonial{
     
    height: 600px;
    width: 100%;
}
.title{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    height: 3rem;
    align-items: center;
    color: black;
    text-align: center;
}


.testimonial-container {
    /* display: flex; */
    position: relative;
    align-items: center;
    justify-content: center;
   height: 750px;
   width: 65%;
    margin-left: 16%;
    margin-top: 2%;
    background-color: #f7f7f7;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    
  }

  .testimonial:hover{
    color: var(--orange);
    background-color: var(--blue);
  }
 
  .testimonial:hover .testimonial-description {
    /* CSS changes for description on hover */
    color: white;
  
  }
  .testimonial:hover .testimonial-name{
    color: white;
  }
  .testimonial {
    position: relative;
    /* background-color:aqua; */
    padding: 20px;
    gap: 20px;
    height: 300px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  .testimonial-image {
    flex: 0.5;
    
    position: relative;
    text-align: center;
    height: 160px;
    width: 160px;
    padding: 10px;
    margin-top: 0;
    margin: 0;
  
    
    border-radius: 50%;
    border: 2px solid var(--orange);
    margin-left: 40%;
  }
  
  .testimonial-image img {
    width: 160px;
    height: 160px;
    position: relative;
    
    object-fit: cover;
    border-radius: 50%;
    padding-top: 0;
    margin-top: 0;
  }
  
  .testimonial-content {
  
    flex: 2;
    padding: 10px 20px;
    
  }
  
  .testimonial-title {
    
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 10%;
    padding-right: 10%;
    color: var(--orange);
  }
  
  .testimonial-description {
    font-size: 19px;
    margin-bottom: 10px;
    padding-top: 3%;
    padding-left: 10%;
    padding-right: 10%;
    
    /* Wrap text onto the next line */
   
  }
  
  .testimonial-name {
    font-size: 19px;
    padding-left: 10%;
    padding-right: 10%;
    
   
  }

  .testimonial-navigation {
    display: flex;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    width: 10%;
    gap: 780px;
    left: 2%;
    color: var(--orange);
    
  }
  
  .arrow-left {
   flex: 1;
    width: 60px !important;
    height: 60px !important;
  
    
    cursor: pointer;
    transition: background-color 0.3s;
    
  }

  .arrow-right {
    flex: 1;
    width: 60px !important;
    height: 60px !important;
   
    
    cursor: pointer;
    transition: background-color 0.3s;
    left: 20%;
  }
  
  testimonial-container:hover .arrow-left{
    color: var(--orange);
  }
  testimonial-container:hover .arrow-right{
    color: var(--orange);
  }
  
  
  
  .testimonial-dots {
    
   position: absolute;
    display: flex;
    justify-content: center;
    z-index: 1;
    left: 50%;
    bottom: 60%;
    /* margin-top: 430px; */
  
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dot.active {
    background-color: var(--orange);
  }
  

  @keyframes bubbleAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .testimonial img{
    transition: transform 0.3s;
  }
  .testimonial img:hover {
    animation: bubbleAnimation 1s ;
  }


@media screen and (max-width: 330px) {
  
  .testimonial-navigation{
    gap: 130px !important;
  }
  
}
  @media screen and (max-width: 380px){
    
    .testimonial-navigation{
      gap: 220px !important;
    }

  }

  @media screen and (max-width: 450px ) {

    .title{
      font-size: 1.3rem;
      margin-bottom: 13%;
    }

    .testimonial-container{
      height: 700px;
      width: 90%;
      left: 0;
      top: 3%;
     margin-left: 0px;
     left: 5%;
   
      
      
    }
    .testimonial{
      height: 350px;
      
    }

  .testimonial-navigation{
     
    gap: 270px;
    right: 0px;
    margin-left: -5%;
  }
  .testimonial-description{
    margin-top: 7%;
  }

  .testimonial-image{
    top: 2%;
margin-left: 28%;

  }
  .testimonial-dots{
    bottom: 50%;
    
  }
 

    
  }

  
  
  