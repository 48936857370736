.wrapper {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 80%;
    margin: 0 auto;
    border: 2px solid;
    position: relative;
    height: 80vh;
    overflow: hidden;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    will-change: transform, opacity;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 25px auto 0 auto;
  }
  
  .controls > span {
    font-size: 2em;
    cursor: pointer;
  }