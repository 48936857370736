.logo-img{
    
    transform: scale(1);
    font-weight: bold;
    margin-top: 0.5rem;
    margin-left: 1rem;
    
}

/* .logo-container{
  align-items: center;
  justify-content: center;
} */

@keyframes popAnimation {
    0% {
      transform: scale(1); /* Initial scale */
    }
    50% {
      transform: scale(1.2); /* Scale to pop effect */
    }
    
  }
  /*
  .logo-img {
    transition: transform 0.3s;
  }
  
  .logo-img:hover {
    animation: popAnimation 1s; 
  }
  */

  @media screen  and (max-width:450px){

    .logo-img{
      font-size: 0.3rem;
      width: 70%;
      margin-left: 0;
    }
    
  }
