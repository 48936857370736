.carousel-image {
    transition: opacity 0.5s ease-in-out; 
    height: 500px;
    width: 100%;
   
  }
  .Craousel-Container{
    
    margin-top: 10%;
    height: 700px;
    padding-top: 10%;
  }

  @keyframes bubbleAnimationCarousel {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
        transform: scale(1);
      }
  }
  
  .carousel-image img {
    transition: transform 0.6s;
  }
  .carousel-image img:hover {
    animation: bubbleAnimationCarousel 1s ;
  }

  @media  screen and (max-width: 450px) {
    
    .carousel-image{
        transition: none;
        height: 200px;
        
        margin-top: 0;
    }
    .carousel-image img{
        transition: none;
        animation: none;
        transform: scale(1);



    }
  }