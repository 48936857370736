/* Product.js */
.product-page {
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    max-width: 80%;
    margin: 0 auto;
   margin-top: 5rem;
    margin-bottom: 5rem;
    gap:8%;
  }
  .p-button{
position: absolute;
  margin-left: 55%;
  margin-top: 60%;
  /* display: none; */
  }

  .details{
    /* CSS */
   position: relative;
      background-color: var(--orange);
      background-image: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
      border: 0;
      border-radius: .25rem;
      box-sizing: border-box;
      color: var(--blue);
      cursor: pointer;
      text-align: center;
      /* padding: 3px 16px 11px 0px; */
      font-size: 1.125rem; /* 18px */
      line-height: 0.5rem;
      font-weight: 600;
      text-align: center !important;
      width: 200px;
      height: 40px;
      left: 18%;
      bottom: 15px;
      display: flex;
  justify-content: center;
  align-items: center;
      
      /* user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation; */

      
    }
    .details:hover{
      color: white;
    }
    
    .details:hover {
      box-shadow: none;
    }
    .product-block:hover .p-button{
      display: block;
    }
    
    
  
.product-block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 400px;
   
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10%;
    /* background-color: #f7f7f7; */
    background-color: var(--blue);
  }
  

  .product-block:hover{
    border: 2px solid var(--orange);
    box-shadow: 0px 0px 2px 2px var(--orange);

  }
  .product-block .product-image{
    flex: 5;
    
    background-color: var(--blue);
    max-width: 100%;
    height: 100%;
    margin: 5%;
    padding-top: 10%;
    padding-left: 5%;
    border: 2px solid #ccc;
    background-color: white;
    
   
  }
  .product-block .product-title{
    flex: 1;
   
    justify-content: center;
    flex: 1;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 20px;
   text-align: center;
   
    
    color: var(--orange);
  }
  .product-block .product-subtitle{
    flex: 1;
   
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
    padding-left: 5%;
  }
  .P-title{
    font-size: 1.5rem;
    
    height: 3rem;
    align-items: center;
    color: black;
   
    text-align: center;
  }
  .P-subtitle{
    color: var(--blue);
    text-align: center;
    
    margin-bottom: 2rem;
  }
  .product-subtitle{

    
  }
  
  .product-image img {
    width: 80%;
  }
  
  .product-title {
    margin-top: 10px;
  }
  
  /* ProductPage.js */
  @keyframes bubbleAnimationCard {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100%{
      transform: scale(1);
    }
    
  }

  .product-block .product-image img{
    transition: transform 0.3 ease;
  }
  .product-block .product-image {
    overflow: hidden;
  }
  .product-block .product-image:hover {
    animation: bubbleAnimationCard 1s forwards;
  }
  
  .DetaiCard{
    position: absolute;
  }
  .detail-container{
    position: absolute;
    z-index: 10;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    margin-top: 3350px;
    /* background-color: rgba(0, 0, 255, 0.5); */
    
    
   
  }

  .detail-card {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 500px;
    width: 100%;
    border: 4px solid var(--orange);

  }
  
  .left-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .left-section h1{
    color: black;
  }
  
  .left-section img {
    margin-top: 20px;
    width: 380px;
    height: 400px;
    object-fit: cover;
  }
  .left-section img:hover{
    animation: bubbleAnimationCard 1s forwards;
  }
  .right-section {
    flex: 1;
    background-color: #fff;
    padding: 20px;
  }
  
  .right-section h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: var(--orange);
    font-weight: bold;
  }
  
  .right-section ul {
    list-style: none;
    padding: 0;
  }
  
  .right-section li {
    border-bottom: 1px solid #ccc;
    padding: 6px 0;
    cursor: pointer;
  }
  
  .right-section li:hover {
    background-color: #eee;
  }
  
  close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ccc;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .close-button {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--orange);
    border: none;
    cursor: pointer;
    font-size: 2rem;
    columns: var(--blue);
    top: 10px;
    right: 2px;
  }
  .close-button:hover{
    background-color: var(--blue);
    color: var(--orange);
  }

  @media screen and (max-width: 450px) {
     .Product-Container{
      
     }
     .product-page{
      flex-direction: column;
     }
     .product-block{
      width: 100%;
     }
     .detail-container{
      
      width: 80%;
      left: 10%;
      margin-top: 4900px;
     }
     .detail-card{
      flex-direction: column;
      height: auto;
      width: 100%;
      gap: 0px;

     }
     .left-section{
      width: 80%;
      height: 40%;
      
     }
     .left-section img{
      width: 100%;
      height: 50%;
     }
  }
  
  

 
  
  