.n-wrapper{
    padding-top: 10px;
    height: 4.9vh;
    display: flex;
    justify-content: space-between;
    background-color: white;
    
}

.n-left{
    flex: 1;
    align-items: center;
    display: flex;
    gap: 5rem;
}
.n-logo{
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.7rem;
}


.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--blue);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    /* top: 50%; */
    margin-top: 30px;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: var(--orange);
  }

.n-right{
    display: flex;
    /* flex-direction: row; */
    flex: 2;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
.n-List{
    /* flex-direction: row; */
    margin-left: auto;
    
    
    
}
.n-List >ul{
    display: flex;
    /* flex-direction: row; */
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer;
    font-size: 1.3rem;
}
.n-List > ul > li{
    flex-direction: row;
}
.n-List >ul> li:hover{
      cursor: pointer;
      color: var(--orange);
}

.n-button{
    display: flex;
    flex: 4;
    margin: 0 10px 10px 0;
    width: 5rem;
   
}

@media screen and (max-width: 450px) {

    .n-wrapper {
        padding-top: 10px;
        height: 10vh;
        display: flex;
        justify-content: space-between;
      }
      .n-left {
        
        gap: 2rem;
      }
      .n-logo{
        transform: scale(0.8);
        left: 10%;
      }
     


   .button{
    display: none;
   }
   .n-List{
    z-index: 12;
   }
   
    .hamburger{
        display: block;
    }
    .n-List > ul{
        flex-direction: column;
    position: absolute;
    /* top: 60px; */
    left: -5%;
    width: 90%;
    /* height: calc(100vh - 60px); */
    height: 300px;
    right: 20%;
    color: var(--blue);
    gap: 2rem;
    background-color: white;
    border-top: 1px solid black;
    font-size: 1.3rem;
    display: none;
   
    }

    .n-List li {
        text-align: center;
        margin-top: 1rem;
        
    }
     .expanded ul{
        display: block;
        
        position: absolute;
        z-index: 13;
       left: 1%;
    }
    
    
}