.toggle{
    display: flex;
    justify-content: center;
    border: 3px solid var(--blue);
    border-radius: 1rem;
    position: relative;
    padding: 2px;
    cursor: pointer;
}
.toggle>*{
    width: 1rem ;
    height: 1rem;
    color: var(--orange);
}

.t-button{
    border-radius: 100%;
    background-color: var(--blue);
    position: absolute;
    /* margin-right: 1rem; */
}

@media  screen and (max-width: 600px) {

    .toggle{
        display: none;
    }
    
}