.Container {
  margin: 0;
 
  width: 100%;
  
 
  height: 220px;
  display: flex;
  flex-direction: row;
}
.brand{

  height: 220px;
  background-color: var(--blue);
  display: flex;
  justify-content: center;

}
.brand-image{
  
  /* background-color: #e67e22; */
  background-color: white;
  /* background-color: var(--blue); */
  scale: 0.9;
  flex:2;
    position: relative;
    text-align: center;
    height: 130px;
    width: 130px;
    padding: 5px;
   justify-content: center;
   align-items: center;
   left: 28%;
   top: 8%;
    border-radius: 50%;
    border: 4px solid var(--orange);

}
.brand-name {
    position: relative;
  font-size: 24px;
  font-weight: bold;
 
margin-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  color: var(--orange);
}

.brand-image img {
  width: 130px;
  height: 130px;
  position: relative;
  
  object-fit: cover;
  border-radius: 50%;
  padding-top: 0;
  margin-top: 0;
}
.brand-name{
  flex: 1;
}

.title{
  font-size: 1.5rem;
  
  height: 2rem;
  align-items: center;
  color: black;
  text-align: center;
}
.slick-next:before, .slick-prev:before {
  color: #000;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}
.center h3 {
  transition: all .10s ease;
}

@keyframes bubbleAnimation {

  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.brand-image {
  transition: transform 0.3s;
}
.brand-image:hover {
  animation: bubbleAnimation 1s ;
}

@media  screen and (max-width: 600px) {

  .brand-name{
    font-size: 1.3rem;
  }
  .brand-image{
    align-items: center;
    left: 12%;
    right: 0;
  }
  
}