.hero-slider{

    margin-top: 2rem;
    width: 100%;
    
    height: 100%;
background-color: white;
position: relative;

}
.Overlay{
    /* background-color: black; */
    /* opacity: 0.3; */
    height: 1000vh;
}
.slide-overlay{
    
    height: 1000vh;
    
}
.Slide-Container{
    background-color: var(--blue);
}
.slides{
    
}

.image-slider{
    background-color: aqua;
}
@media screen  and(max-width: 450px){
   
    .hero-slider{
        width: 60%;
        height: 60%;
    }
}
