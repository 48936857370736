
  .Business{

  }
  .B-title{
    font-size: 1.5rem;
    
    height: 3rem;
    align-items: center;
    color: black;
   
    text-align: center;
  }
  .B-subtitle{
    color: var(--blue);
    text-align: center;
    
    margin-bottom: 2rem;
  }
  .B-summary{

    margin-top: 1rem;
    font-size: 1.5rem;
    
 
   
    width: 78%;
    margin-left: 8.5%;
    color: black;
    
    border-radius: 10px;
    text-align: center;
    cursor: pointer !important;
    padding-left: 2rem; 
    padding-right: 2rem;
    /* border: 2px solid var(--blue); */
    border: 2px solid #ccc;
    /* box-shadow: 0px 0px 2px 2px var(--orange);  */
    margin-bottom: 3rem;
    background-color: #f7f7f7;
    /* background-color: var(--blue); */
    /* color: white; */
  }

  .B-summary:hover{
    border: 2px solid var(--orange);
    box-shadow: 0px 0px 2px 2px var(--orange);
    background-color: var(--blue);
    color: white;

  }

  .service-container {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    margin: 0 auto;
    gap: 8%;
    margin-bottom: 5rem;
  }
  
  .service-block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 400px;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    /* background-color: #f7f7f7; */
    background-color: var(--blue);
    
  }
  .service-block:hover{
    border: 2px solid var(--orange);
    box-shadow: 0px 0px 2px 2px var(--orange);

  }
  .service-block:hover .B-logo{
    color: var(--blue);
    background-color: var(--orange);
  }
  
  .service-block img {
    flex: 2;
    max-width: 100%;
    height: auto;
  }
  
  .service-block h3 {
    margin-top: 10px;
    flex: 1;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
   
    
    color: var(--orange);
  }
  
  .service-block p {
    flex: 8;
    margin-top: 10px;
    font-size: 20px;
    margin-bottom: 10px;
    color: white;
    
  }
  .service-block div img{
    position: absolute;

  }
  .B-logo{
    position: absolute;
    text-align: center;
    width: 80px;
    height: 80px;
    line-height: 60px;
    border-radius: 50%;
    font-size: 24px;
    border: 2px solid var(--orange);
    /* background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%); */
    color: var(--orange);
    background-color: var(--blue);
    
    
    z-index: 1;
    left: 65%;
    top: 40%;
  }

  @keyframes bubbleAnimationBusiness {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .service-block img{
    transition: transform 0.3s;
  }
  .service-block img:hover {
    animation: bubbleAnimationBusiness 1s ;
  }
  .logosize{
   transform: scale(3);
   padding-top: 17%;
  }
  
  @media screen and (max-width: 450px) {
    
    .service-container{
        flex-direction: column;
        
    }
    
    .service-block{
        width: 80%;
        height: 400px;
        margin-left: 4%;
       margin-bottom: 80px;
    }
    .B-summary{
       width: 70%;
       margin-left: 7.5%;
       font-size: 19px;

    }
    .B-logo{

      width: 60px;
      height: 60px;
      left: 70%;
      top: 38%;
    }

    .logosize{

      transform: scale(2);
    }
  }