:root
{
  --gray: #e4e7e7;
  --yellow: #F5C32C;
    --orange : #fb982f;
    --orange1: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
    /* --orange: rgb(107,188,15); */
    /* --orange: #89BEEF; */
    /* --orange:  #E1AD01; */
    /* --black : #242D49; */
    --black: black;
    --blue: #162a46;
    /* --gray : #788097; */
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(251,161,40, 0.42);
    /* --orangeCard: #162a46; */
    /* --orangeCard: rgb(58, 102, 7); */
    /* --orangeCard: rgb(107,188,15); */
    /* --orangeCard: #89BEEF; */
     /* --orangeCard: #E1AD01; */
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    /* background-color: #F5C32C; */
}

.App{
    /* padding: 0.2rem; */
    overflow: hidden;
    color: var(--black);    
    
    
    
    
}

.button{
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: #162a46;
  /* background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%); */
  /* background: rgb(107, 188, 15); */
  /* background: #89BEEF; */
  /* background:  #E1AD01; */
  

  /* box-shadow: 0px 20px 24px 3px rgba(251,161,40, 0.42); */
  
}
.button:hover{
  /* background: #162a46; */
  background:linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  /* background-: #89BEEF; */
  /* background: white; */
  cursor: pointer;
  /* color: var(--orange); */
  /* color: var(--orange); */
  /* border: 1px solid var(--orange); */

}


.Headroom{
 
}